export type HreflangElement = {
  locale?: string;
  lang?: string;
  defaultCountry?: string;
};

export type HreflangMapping = Array<HreflangElement>;

export const hreflangMapping = [
  { locale: 'it', lang: 'it', defaultCountry: 'IT' },
  { locale: 'en', lang: 'en', defaultCountry: 'US' },
] as HreflangMapping;
