import { useContext, useRoute, ref, computed } from '@nuxtjs/composition-api';
import { Config } from '@gemini-vsf/api-client';
import { useGeminiApi } from '~/composables';
import { useGetAlternateMatching } from '~/helpers/alternate/getAlternateMatching';
import { sharedRef } from '@vue-storefront/core';

interface LocalConfig extends Config {
  geminiBaseUrl: string;
}

export default () => {
  const {
    app: {
      $vsf: {
        $gemini: { config },
      },
    },
  } = useContext();
  const { geminiBaseUrl } = config as LocalConfig;
  const route = useRoute();
  const { path } = route.value;
  const seoAlternateurls = sharedRef([], `alternates-seo-helpers`);
  const alternate = computed(() => seoAlternateurls.value as Array<unknown>);
  const { getGeminiData, geminiData } = useGeminiApi();
  const { getAlternateArray, getAlternatePageArray } = useGetAlternateMatching();

  const getGeminiDataFunction = async (grn: string) => {
    await getGeminiData(grn);
    seoAlternateurls.value = getAlternateArray(geminiData.value as Array<unknown>);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return seoAlternateurls.value;
  };

  const getAlternates = async (grn = '', isCms = false) => {
    if (!isCms) {
      await getGeminiDataFunction(grn);
      return alternate.value;
    }
    return getAlternatePageArray();
  };

  const getCanonical = () => ({
    rel: 'canonical',
    href: `${geminiBaseUrl || 'https://www.treponti.com'}${path}`,
  });

  return {
    getAlternates,
    getCanonical,
  };
};
