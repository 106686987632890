/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, useRoute } from '@nuxtjs/composition-api';
import pagesOptions from '~/helpers/alternate/cmsMapping';
import { HreflangElement, hreflangMapping } from './hreflangMapping';

export const useGetAlternateMatching = () => {
  const {
    app: {
      $vsf: {
        $gemini: {
          // @ts-ignore
          config: { geminiBaseUrl },
        },
      },
    },
  } = useContext();
  const route = useRoute();
  const alternateArray = [];
  const cmsPagesUrlMapping = pagesOptions;

  const buildHreflang = (mappingElement: HreflangElement) => {
    return `${mappingElement.lang}-${mappingElement.defaultCountry.toUpperCase()}`;
  };
  const prepareAlternateData = (mapping?: HreflangElement, url?: string) => {
    const hreflang = buildHreflang(mapping);
    const elementToAdd = {
      rel: 'alternate',
      hreflang,
      href: `${geminiBaseUrl || 'https://www.treponti.com'}/${mapping.locale}/${url ?? ''}`.replace(/\/$/, ''),
    };
    alternateArray.push(elementToAdd);
    if (mapping.locale === 'it') {
      const xDefaultElementToAdd = {
        rel: 'alternate',
        hreflang: 'x-default',
        href: `${geminiBaseUrl || 'https://www.treponti.com'}/it/${url ?? ''}`.replace(/\/$/, ''),
      };
      alternateArray.push(xDefaultElementToAdd);
    }
  };

  const getCmsUrl = (cmsMappingElementFound: any, langCode: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    let url = cmsMappingElementFound[`${langCode}`] as string;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (langCode === 'it') url = cmsMappingElementFound[`${langCode}`] as string;
    return url;
  };

  const createPageAlternateArray = (urlPath: string) => {
    const pageData = cmsPagesUrlMapping.filter((element) => Object.values(element).includes(urlPath ?? ''));
    if (pageData.length === 0 && route.value.name?.toLowerCase().includes('home')) {
      hreflangMapping.forEach((mapping) => prepareAlternateData(mapping, ''));
    }
    if (pageData.length > 0) {
      hreflangMapping.forEach((mapping) => prepareAlternateData(mapping, getCmsUrl(pageData[0], mapping.lang)));
    }
    return (alternateArray || []) as [];
  };
  const createAlternateArray = (geminiData: Array<unknown>) => {
    if (geminiData && geminiData.length > 0) {
      hreflangMapping.forEach((mapping) => {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        const dataMatchingLang = geminiData.filter((element) => element.context.includes(mapping.lang));
        if (dataMatchingLang && dataMatchingLang.length > 0) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          prepareAlternateData(mapping, dataMatchingLang[0].requestPath as string);
        }
      });
    }

    return (alternateArray || []) as [];
  };

  const getAlternateArray = (geminiData: Array<unknown>) => {
    return createAlternateArray(geminiData);
  };

  const getAlternatePageArray = () => {
    const path = route.value?.params?.path || route.value?.path.split('/')[2];
    return createPageAlternateArray(path);
  };

  return {
    createPageAlternateArray,
    cmsPagesUrlMapping,
    getAlternateArray,
    createAlternateArray,
    getAlternatePageArray,
  };
};
