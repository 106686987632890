/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { Product, useCart } from '@gemini-vsf/composables';
// import { useRouter, useContext } from '@nuxtjs/composition-api';
import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';

export const useAddToCart = () => {
  const { addItem: addItemToCartBase, isInCart } = useCart();

  const trans = useI18n();
  const { send: sendNotification } = useUiNotification();

  // const router = useRouter();
  // const { app } = useContext();
  const addItemToCart = async (params: { product: Product; quantity: number }) => {
    const { product, quantity } = params;
    // eslint-disable-next-line no-underscore-dangle
    // @ts-ignore
    const productType = product.vsf_typename;

    switch (productType) {
      case 'SimpleProduct': {
        await addItemToCartBase({
          product,
          quantity,
        });
        break;
      }
      // case 'ConfigurableProduct':
      //   const path = `/p/${productGetters.getProductSku(product)}${
      //     productGetters.getSlug(product, product.categories[0])
      //   }`;
      //   await router.push(String(app.localePath(path)));
      //   break;
      default: {
        throw new Error(`Product Type ${productType} not supported in add to cart yet`);
      }
    }
  };

  const showCartError = (cartError: Error) => {
    sendNotification({
      id: Symbol('cartError'),
      type: 'info',
      icon: 'error',
      title: `${trans.t('An error occurred')}`,
      message: `${trans.t(cartError.message)}`,
    });
  };

  return {
    addItemToCart,
    isInCart,
    showCartError,
  };
};
