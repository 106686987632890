/* eslint-disable @typescript-eslint/no-explicit-any */
import { Context as NuxtContext } from '@nuxt/types';
import { merge } from 'lodash-es';
import c from 'cookie';
import cookieNames from '~/enums/cookieNameEnum';
import { languageMap } from '~/enums/languagesEnum';

const MARKET_GRN = 'grn:channelmanager:market::65844569ea12e52585591fe3';

export type ApiClientMethod = (...arguments_: Array<unknown>) => Promise<unknown>;

interface CreateProxiedApiParams {
  givenApi: Record<string, ApiClientMethod>;
  client: any;
  tag: string;
}

export const createProxiedApi = ({ givenApi, client, tag }: CreateProxiedApiParams) =>
  new Proxy(givenApi, {
    get: (target, property, receiver) => {
      const functionName = String(property);
      if (Reflect.has(target, functionName)) {
        return Reflect.get(target, property, receiver);
      }

      return async (...arguments_) => client.post(`/${tag}/${functionName}`, arguments_).then((r) => r.data);
    },
  });

export const getCookies = (context: NuxtContext) => context?.req?.headers?.cookie ?? '';

export const getIntegrationConfig = (context: NuxtContext, configuration: any) => {
  const {
    req: { headers: { cookie: cookiesFromReq } = {} } = {}, $config = {}, route: { path }
  } = context;
  let cookie = '';
  if (process.client) {
    cookie = getCookies(context);
  } else {
    const currentLocale = path?.split('/')?.[1] || 'it';
    const market = $config?.marketGrn || MARKET_GRN;
    const cookiesObject = c.parse(cookiesFromReq ?? '');
    cookiesObject[cookieNames.acceptLanguageCookieName] = languageMap[currentLocale];
    cookiesObject[cookieNames.marketCookieName] = market;
    cookiesObject[cookieNames.currencyCookieName] = 'EUR';
    cookiesObject[cookieNames.localeCookieName] = currentLocale;

    let newCookieString = '';
    Object.keys(cookiesObject).forEach((key) => {
      newCookieString += `${key}=${cookiesObject[key]}; `;
    });
    cookie = newCookieString.trim().slice(0, -1);
  }
  return merge(
    {
      axios: {
        headers: {
          ...(cookie ? { cookie } : {}),
        },
      },
    },
    configuration
  );
};
