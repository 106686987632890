export const userErrorMap = {
  updateUser: 'Update user',
  register: 'Register',
  login: 'Login',
  logout: 'Logout',
  changePassword: 'Change password',
  load: 'Loading',
  attachToCart: 'Guest',
};

interface ErrorMap {
  [step: string]: {
    [key: string]: {
      title: string;
      message: string;
    };
  };
}

export const checkoutErrorsMap: ErrorMap = {
  generic: {
    invalidStep: {
      title: 'Invalid step',
      message: 'Something went wrong, going back to a previous step.',
    },
  },
  shipping: {
    load: {
      title: 'Loading error',
      message: 'Could not load shipping information',
    },
    save: {
      title: 'Saving error',
      message: 'Could not save shipping information',
    },
  },
  billing: {
    load: {
      title: 'Loading error',
      message: 'Could not load billing information',
    },
    save: {
      title: 'Saving error',
      message: 'Could not save billing information',
    },
  },
  payment: {
    make: {
      title: 'Payment error',
      message: 'Could not make payment',
    },
    make_50: {
      title: 'Payment error',
      message: 'Please retry',
    },
    make_50_100: {
      title: 'Payment error',
      message: 'Your cart is outdated and it will be reloaded now.',
    },
    make_100_200: {
      title: 'Payment error',
      message: 'Could not make payment',
    },
    make_200: {
      title: 'Payment error',
      message: 'Could not make payment',
    },
  },
};

export const filterLabelsMap = {
  size: 'Size',
  color: 'Color',
  edge: 'Edge',
  model: 'Model',
  refractive: 'Refractive',
  material: 'Material',
  length: 'Length',
  flake: 'Flake',
};
