/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

/**
 * Sanitizes incoming data to be used in structured data
 * Unfortunately PayloadCMS doesn't allow to use special characters in values (like @ in @type)
 */
const sanificateIncomingData = (obj) => {
  if (typeof obj !== 'object' || obj === null) return obj;

  return Object.entries(obj).reduce(
    (newObj, [key, value]) => {
      if (['id', 'blockName', 'blockType'].includes(key)) return newObj;
      let newKey = key.includes('type') || key.includes('Type') ? '@type' : key;
      newKey = key.includes('name') || key.includes('Name') ? 'name' : newKey;
      newKey = key === 'context' ? '@context' : newKey;
      newObj[newKey] = sanificateIncomingData(value);
      if (key === 'imagesArray') {
        // @ts-ignore
        const newImagesArray = value.map((i) => i.image.url);
        delete newObj[key];
        return {
          ...newObj,
          image: newImagesArray,
        };
      }
      if (key === 'eventAttendanceMode' || key === 'eventStatus') {
        newObj[key] = `https://schema.org/${value}`;
        return newObj;
      }
      return newObj;
    },
    Array.isArray(obj) ? [] : {}
  );
};

export const generateEventStructuredData = (payloadData) => {
  try {
    return sanificateIncomingData(payloadData);
  } catch (error) {
    console.error(' generateEventStructuredData ~ error:', error);
  }
  return {};
};

export const generateBreadcrumbStructuredData = (data, source, localePath, storeUrl) => {
  try {
    const itemListElement = data.map((element, index) => {
      const name = source === 'payload' ? element.breadcrumbLabel : element.text;
      const item = source === 'payload' ? `${storeUrl}${localePath(element.breadcrumbUrl)}` : `${storeUrl}${element.link}`;

      return {
        '@type': 'ListItem',
        position: Number.parseInt(index, 10) + 1,
        name,
        item,
      };
    });

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement,
    };
  } catch (error) {
    console.error('Could not ~ generateBreadcrumbStructuredData ~ error:', error);
  }

  return {};
};
